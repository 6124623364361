import React from "react"
import Layout from "../components/layout"
import phone from "../images/icons/phone-call.png"
import fax from "../images/icons/fax.png"
import mail from "../images/icons/mail.png"
import address from "../images/icons/pin.png"

// professors:

import yacine from "../images/Profs/Yacine.jpg"

import ozgur from "../images/Profs/ozgur.jpg"

import halima from "../images/Profs/halima.jpg"

import glitho from "../images/Profs/Glitho.jpg"

import wessam from "../images/Profs/Ajib.jpg"

const professors = [
  {
    image: ozgur,
    name: "Prof. Ozgur Ercetin",
    title: "Sabanci University",
    email: "oercetin@sabanciuniv.edu",
  },
  {
    image: halima,
    name: "Prof. Halima Elbiaze",
    title: "UQAM",
    email: "elbiaze.halima@uqam.ca",
  },
  {
    image: glitho,
    name: "Prof. Roch H. Glitho",
    title: "Concordia University",
    email: "Glitho@ciise.concordia.ca",
  },
  {
    image: wessam,
    name: "Prof. Wessam Ajib",
    title: "UQAM",
    email: "ajib.wessam@uqam.ca",
  },
]

function PI({ image, name, title, email }) {
  return (
    <div className="flex  flex-col items-center justify-center p-4">
      <img src={image} alt={name} className="w-40 rounded-full"></img>
      <h3 className="text-scoringBlue m-2 text-center">{name}</h3>
      <h4 className="text-center m-2">{title}</h4>
      <p className="flex flex-row items-center gap-1">
        <span title="Email">
          <img src={mail} className=" w-4" />
        </span>{" "}
        <a
          className="font-semibold text-xs text-scoringBlue"
          href={`mailto:${email}`}
        >
          {email}
        </a>
      </p>
    </div>
  )
}

function ContactPage({ location }) {
  return (
    <Layout pagePath={location.pathname}>
      <div className="pt-10">
        <h1 className="text-scoringBlue">Contacts</h1>
        <div>
          <img src={yacine} className=" w-40  rounded-full"></img>
          <p>
            <strong>Project Coordinator :</strong> Prof. Yacine Ghamri-Doudane
          </p>
          <p className="flex flex-row items-center gap-1">
            <span title="Address">
              <img src={address} className="w-6" />
            </span>{" "}
            Laboratoire Informatique, Image et Interaction (L3i), La Rochelle
            Université, Av. Michel Crépeau, 17042 La Rochelle
          </p>
          <p className="flex flex-row items-center gap-1">
            <span title="Phone">
              <img src={phone} className="w-6" />
            </span>{" "}
            +33 5 46 45 82 42
          </p>
          <p className="flex flex-row items-center gap-1">
            <span title="Phone">
              <img src={fax} className="w-6" />
            </span>{" "}
            +33 5 46 45 82 62
          </p>
          <p className="flex flex-row items-center gap-1">
            <span title="Email">
              <img src={mail} className="w-6" />
            </span>{" "}
            <a
              className="font-semibold text-scoringBlue"
              href="mailto:yacine.ghamri@univ-lr.fr"
            >
              yacine.ghamri@univ-lr.fr
            </a>
          </p>
        </div>
        <h1 className="p-4">Principal Investigators</h1>
        <ul className="flex flex-col md:flex-row">
          {professors.map(prof => (
            <li>
              <PI {...prof}></PI>
            </li>
          ))}
        </ul>
      </div>
    </Layout>
  )
}

export default ContactPage
